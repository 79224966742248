<template>
  <button class="btn info" @click="model = !model">
    <i :class="{ 'fas fa-compress': !model, 'fas fa-expand': model }"></i> {{ model ? 'Expand' : 'Compact' }}
  </button>
</template>

<script setup>
import { useVModel } from '@vueuse/core'

const props = defineProps({
  modelValue: Boolean,
})

const emit = defineEmits(['update:modelValue'])
const model = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
</script>
